import { useEffect } from "react";
import { useState } from "react";
import { PiArrowUp } from "react-icons/pi";

const ScrollButton = () => {
    const [visible, setVisible] = useState(false);

    const toggleVisible = () => {
        const scrolled = document.querySelector(".scrollbar").scrollTop;
        if (scrolled > 300) {
            setVisible(true);
        } else if (scrolled <= 300) {
            setVisible(false);
        }
    };

    const scrollToTop = () => {
        document.querySelector(".scrollbar").scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    useEffect(() => {
        const rootElement = document.querySelector(".scrollbar");
        rootElement.addEventListener("scroll", toggleVisible);
    }, []);

    return (
        <button
            className={`rounded-full p-3 md:p-4 lg:p-4 border-2 border-primary right-4 bottom-4 md:right-16 md:bottom-16  lg:right-16 lg:bottom-16 z-50 bg-black fixed transition-all ${
                visible
                    ? "opacity-100 translate-y-0"
                    : "opacity-0 translate-y-20 pointer-events-none"
            }`}
            onClick={scrollToTop}
            aria-label="Scroll to top of page"
            title="Scroll to top of page"
        >
            <PiArrowUp color="#2FC0AD" size={35} />
        </button>
    );
};

export default ScrollButton;
