import Footer from "@/client/PageComponents/Footer";
import Navbar from "@/client/PageComponents/Navbar";
import ScrollButton from "@/client/PageComponents/ScrollButton";

export default function Guest({ children }) {
    return (
        <div
            className="scrollbar bg-black w-screen h-screen max-w-screen max-h-screen overflow-y-auto overflow-x-hidden antialiased"
            data-color-mode="dark"
        >
            <Navbar />
            {children}
            <ScrollButton />
            <Footer />
        </div>
    );
}
