import { useState } from "react";
import logo from "@/assets/logo.svg";
import Text from "./Text";

const menu = [
    { label: "Home", link: "/#home" },
    { label: "Why us?", link: "/#why-us" },
    { label: "Services", link: "/#services" },
];

const actions = [
    { label: "Blog", link: "/blog" },
    { label: "Get in touch", link: "/contact" },
];

function Navbar() {
    return (
        <>
            <div className="w-full hidden md:block lg:block">
                <DesktopMenu />
            </div>
            <div className="w-full block md:hidden lg:hidden">
                <MobileMenu />
            </div>
        </>
    );
}
 
const DesktopMenu = () => {
    return (
        <div className="w-full fixed top-0 z-50">
            <div className="flex flex-row justify-between items-center max-w-screen-2xl m-auto py-6 bg-gradient-to-b from-black to-transparent z-20">
                <div>
                    <a
                        href="/"
                        aria-label="Go to the arcane studio homepage"
                        title="Go to the arcane studio homepage"
                    >
                        <img
                            src={logo}
                            className="h-10 w-auto md:h-16 lg:h-16"
                            alt="The arcane studio logo"
                        />
                    </a>
                </div>
                {/* Desktop Menu */}
                <div className="flex-row flex">
                    {menu.map((menuItem, index) => (
                        <a
                            key={`menu_${index}`}
                            href={menuItem.link}
                            className="px-4 py-3"
                            aria-label={`Navigate to the ${menuItem.label} section`}
                            title={`Navigate to the ${menuItem.label} section`}
                        >
                            <Text>{menuItem.label}</Text>
                        </a>
                    ))}
                </div>
                <div className="flex-row flex">
                    {actions.map((menuItem, index) => (
                        <a
                            key={`action_${index}`}
                            href={menuItem.link}
                            className="px-4 py-3"
                            aria-label={`Navigate to ${menuItem.label}`}
                            title={`Navigate to ${menuItem.label}`}
                        >
                            <Text>{menuItem.label}</Text>
                        </a>
                    ))}
                </div>
            </div>
        </div>
    );
};

const MobileMenu = () => {
    const [show, setShow] = useState(false);
    return (
        <>
            <div className="flex flex-row justify-between items-center max-w-screen-2xl m-auto py-6 px-3 bg-black w-full fixed top-0 z-50">
                <div>
                    <a
                        href="/"
                        aria-label="Go to the arcane studio homepage"
                        title="Go to the arcane studio homepage"
                    >
                        <img
                            src={logo}
                            className="h-10 w-auto md:h-16 lg:h-16"
                            alt="The arcane studio logo"
                        />
                    </a>
                </div>
                <div className="flex-row flex md:hidden lg:hidden">
                    <button
                        className="flex flex-col gap-[10px]"
                        onClick={() => setShow(!show)}
                        aria-label="Open drawer menu"
                        title="Open drawer menu"
                    >
                        <span
                            className={`h-[3px] w-10 bg-primary rounded-sm transition-all ${
                                show ? "rotate-45 translate-y-[13px]" : ""
                            }`}
                        ></span>
                        <span
                            className={`h-[3px] w-10 bg-primary rounded-sm transition-all ${
                                show ? "opacity-0" : ""
                            }`}
                        ></span>
                        <span
                            className={`h-[3px] w-10 bg-primary rounded-sm transition-all ${
                                show ? "-rotate-45 -translate-y-[13px]" : ""
                            }`}
                        ></span>
                    </button>
                </div>
            </div>
            <div
                className={`flex ${
                    show ? "opacity-100" : "opacity-0 pointer-events-none"
                } w-full fixed top-0 z-20 flex-col justify-center items-center h-screen flex-grow backdrop-blur-lg bg-black/30 transition-all`}
            >
                <div className="flex flex-col justify-center items-center">
                    {menu.map((menuItem, index) => (
                        <a
                            key={`menu_${index}`}
                            href={menuItem.link}
                            className="px-4 py-3"
                            aria-label={`Navigate to the ${menuItem.label} section`}
                            title={`Navigate to the ${menuItem.label} section`}
                        >
                            <Text className="font-bold">{menuItem.label}</Text>
                        </a>
                    ))}
                </div>
                <div className="flex flex-col justify-center items-center">
                    {actions.map((menuItem, index) => (
                        <a
                            key={`action_${index}`}
                            href={menuItem.link}
                            className="px-4 py-3"
                            aria-label={`Navigate to ${menuItem.label}`}
                            title={`Navigate to ${menuItem.label}`}
                        >
                            <Text className="font-bold">{menuItem.label}</Text>
                        </a>
                    ))}
                </div>
            </div>
        </>
    );
};

export default Navbar;
