import { PiFacebookLogo, PiLinkedinLogo, PiXLogo } from "react-icons/pi";
import Text from "./Text";

export default function Footer() {
    const social = [
        {
            label: "Facebook",
            link: "https://www.facebook.com/theArcaneStudios",
            icon: <PiFacebookLogo color="#2FC0AD" size={45} />,
        },
        {
            label: "LinkedIn",
            link: "https://www.linkedin.com/company/the-arcane-studios",
            icon: <PiLinkedinLogo color="#2FC0AD" size={45} />,
        },
        {
            label: "X",
            link: "https://twitter.com/theArcaneStudio",
            icon: <PiXLogo color="#2FC0AD" size={45} />,
        },
    ];

    const menu = [
        { label: "Home", link: "/#home" },
        { label: "Blog", link: "/blog" },
        { label: "Contact Us", link: "/contact" },
        { label: "Privacy Policy", link: "/privacy-policy" },
    ];

    return (
        <footer className="py-10 bg-dark-grey w-full">
            <div className="flex flex-row justify-center items-center m-auto mb-6 gap-10">
                {social.map(({ link, icon, label }, index) => (
                    <a
                        key={`social_${index}`}
                        href={link}
                        target="_blank"
                        aria-label={`Go to the arcane studio ${label} page`}
                        title={`Go to the arcane studio ${label} page`}
                    >
                        {icon}
                    </a>
                ))}
            </div>
            <div className="flex flex-row justify-center items-center mb-6">
                {menu.map((menuItem, index) => (
                    <a
                        key={`menu_${index}`}
                        href={menuItem.link}
                        className="px-2 md:px-4 lg:px-4"
                        aria-label={`Navigate to the ${menuItem.label} section`}
                        title={`Navigate to the ${menuItem.label} section`}
                    >
                        <Text className="font-bold">{menuItem.label}</Text>
                    </a>
                ))}
            </div>
            <div className="mx-auto text-center">
                <span className="text-md md:text-lg lg:text-lg text-primary sm:text-center font-body">
                    © 2023-2024 The Arcane Studio™. All Rights Reserved.
                </span>
            </div>
        </footer>
    );
}
